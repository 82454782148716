/// <references types="houdini-svelte">

/** @type {import('houdini').ConfigFile} */
const config = {
	schemaPath: 'schema.graphql',
	watchSchema: {
		url: 'https://graphql.contentful.com/content/v1/spaces/8sncz7ye3vrh?access_token=QSHsJ8o-yEtx0Gyn5KUm_aaXj_YUujOrNfkafhgQeLc'
	},
	plugins: {
		'houdini-svelte': {}
	},
	scalars: {
		JSON: {
			type: 'Record<string, any>'
		},
		Circle: {
			type: 'Record<any, number>'
		},
		DateTime: {
			type: 'Date',
			// turn the api's response into that type
			unmarshal(val) {
				return val ? new Date(val) : null
			},
			// turn the value into something the API can use
			marshal(date) {
				return date && date.getTime()
			}
		}
	}
}

export default config
